.Image {
  width: 100%;
  border-radius: 30px;
}

.img_container {
  width: 80%;
  aspect-ratio: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 100px;
  border-radius: 20px;
  padding: 1px;
  background-color: white;
}

.img_container div {
  position: unset;
}

.header_text_1 {
  font-size: 40px;
  line-height: 50px;
  width: 80%;
  margin-bottom: 0px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #fafafa;
}

.header_text_2 {
  font-size: 30px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #fafafa;
  width: 80%;
  margin-top: 3vw !important;
}

.cbutton {
  background: #fafafa;
  display: flex;
  justify-content: center;
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  margin-bottom: 20px;
}

.coming_soon {
  font-family: "Poor Story", cursive;
  font-size: 150%;
  font-weight: 600;
  color: #e63946b2;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.background {
  background-image: url("images/common/bg/home_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px #0a1b33b2;
}

.button_wrap_div {
  width: 80%;
}

.side_fixed_button {
  position: fixed;
  z-index: 10;
  background: #efefef;
  width: 50px;
  height: 200px;
  top: 15%;
  right: 0;
  overflow: visible;
  white-space: nowrap;
  display: flex;
  justify-content: bottom;
  cursor: pointer;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.side_fixed_button_text {
  font-family: "Poppins", sans-serif;
  transform: rotate(-90deg);
  height: 110px;
  margin: auto;
}

.partners_container {
  background-color: rgb(29, 53, 87);
  padding: 10px;
}

.partners_header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 90px;
  color: rgba(250, 250, 250, 1);
  padding-left: 50px;
  margin-bottom: 10px;
}

.partner_image {
  height: 200px;
  width: auto;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .partners_header {
    font-size: 30px;
    text-align: center;
    padding-left: 0px;
    line-height: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header_text_1 {
    width: 100%;
    font-size: 35px !important;
    line-height: 30px;
  }

  .header_text_2 {
    width: 100%;
    font-size: 25px !important;
    line-height: 30px !important;
    text-align: center;
  }

  .cbutton {
    background: #fafafa;
    display: flex;
    justify-content: center;
    width: 80%;
  }

  .coming_soon {
    font-family: "Poor Story", cursive;
    font-size: 150%;
    color: #e63946b2;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  .button_wrap_div {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .header_text_1 {
    margin-top: 0px !important;
    font-size: 30px !important;
    line-height: 40px;
  }
  .header_text_2 {
    margin-top: 30px !important;
    font-size: 25px;
    line-height: 40px;
  }

  .partners_header {
    font-size: 30px;
    text-align: center;
    padding-left: 0px;
    line-height: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 458px) {
  .img_container {
    width: 100%;
  }

  .header_text_1 {
    font-size: 35px;
  }
  .header_text_2 {
    font-size: 20px;
  }

  .coming_soon {
    font-family: "Poor Story", cursive;
    font-size: 100%;
    color: #e63946b2;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  .cbutton {
    width: 80%;
  }
}
