.faq_container {
  background: rgba(69, 123, 157, 1);
  border-radius: 60px;
  margin-top: 50px;
  padding-bottom: 1%;
  margin-bottom: 5%;
}

.header {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  line-height: 80px;
  color: rgb(250, 250, 250);
  margin-bottom: 0px !important;
  padding-top: 20px;
  text-align: center;
}

.question_div {
  background-color: #fefefe;
  border-radius: 22px;
  width: 9%;
  margin: auto;
  margin-block: 10px;
}

.question_header {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 36px;
  text-align: left;
  margin-left: 3%;
  margin-bottom: 0px;
  padding-top: 5px;
}

.question_answer {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: black;
  width: 90%;
  margin: auto;
  text-align: left;
  padding-bottom: 10px;
}

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  width: 50%;
  margin: 5rem auto;
}

.accordion__button {
  padding: 1rem;
  border: none;
  width: 100%;
  text-align: left;
}

.accordion__drawer {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.accordion__paragraph {
  margin: 0;
  padding: 1rem;
}
