.header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 90px;
  color: rgba(250, 250, 250, 1);
  padding-left: 50px;
  margin-bottom: 0px;
}

.responsive {
  width: auto;
  height: 100%;
  background: rgba(53, 113, 146, 1);
  padding: 20px;
  margin: auto;
  border-radius: 20px;
}

.content_div {
  display: flex;
  color: rgba(238, 238, 238, 1);
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
  margin: auto;
  text-align: center;
  width: 80%;
}

.customLeft {
  display: flex;
  color: white;
  font-family: "Poppins", sans-serif;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.rarity_name {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: white;
  margin: auto;
  margin-left: 4px;
}

.percentage_text {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
}

.table_container {
  background: rgba(53, 113, 146, 1);
  margin: auto;
  padding: 25px;
  border-radius: 20px;
}

.half_background::before {
  color: green;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 50%;
  height: 100%;
}

.full_background {
  color: white;
}

@media screen and (max-width: 977.5px) {
  .header {
    font-size: 35px;
    text-align: center;
    padding-left: 0px;
    line-height: 40px;
    padding-top: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .content_div {
    width: 100% !important;
  }

  .custom_image {
    width: 80%;
  }
}
