.custom_modal {
  border-radius: 40px !important;
  background-color: #fff !important;
  overflow: hidden !important;
  border-width: 0 !important;
}

.modal_title {
  color: #000000 !important;
  padding-top: 5px !important;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 30px !important;
  border: 0px !important;
  margin-top: 1% !important;
}

.Image {
  width: 50%;
  height: auto;
}

.SummonButton {
  width: 25%;
  padding: 10px;
  display: flex;
  cursor: pointer;
}

.SummonText {
  font-family: "Poppins", sans-serif;
  margin: auto;
  background-color: #1d3557;
  padding: 7px 14px;
  border-radius: 20px;
  color: white;
}

@media screen and (max-width: 768px) {
  .modal_title {
    text-align: center !important;
    margin: auto !important;
    font-size: 20px !important;
    width: 80%;
  }

  .Image {
    width: 80%;
  }

  .SummonButton {
    width: 50%;
  }
}
