.custom_modal {
  border-radius: 40px !important;
  background-color: #c7c7c7 !important;
  overflow: hidden !important;
}

.modal_title {
  color: green;
  padding-top: 5px !important;
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 50px !important;
  margin-bottom: 0 !important;
  border: 0px !important;
  border-width: 0px !important;
}

.sub_header {
  font-family: "Poppins", sans-serif !important;
  font-size: 24px !important;
  text-align: center !important;
}

.nftImage_bg_div {
  width: 330px;
  aspect-ratio: 1;
  background-color: #fefefe;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 30px;
}

.custom_list_group {
  width: 100%;
}

.custom_list_item {
  display: flex !important;
  flex-direction: row;
  background-color: transparent !important;
  border-width: 0px !important;
  width: 100% !important;
  padding: 1px;
}

.list_circle {
  height: 12px;
  width: 12px;
  background-color: #e63946;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  border-width: 0px;
}

.attribute_name {
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 23px;
}

.attribute_real_name {
  width: 50%;
  margin-left: auto;
  margin-right: 10px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  border-radius: 50px !important;
}

.attribute_text {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-size: 23px;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 25px;
}

.nftImage_base_div {
  width: 90%;
  aspect-ratio: 1;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 20px;
}

.nft_div_layer {
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.button_div {
  border-radius: 20px;
  justify-content: center;
  margin-right: 10px;
}

.button_text {
  font-family: "Poor Story", cursive;
  line-height: 80px;
  color: white;
  font-size: 35px;
  padding: 10px 10px;
  border-radius: 20px;
  background-color: red;
  cursor: pointer;
}

.redirect_text {
  color: black;
}

.redirect_text:hover {
  color: blue;
}

@media screen and (max-width: 1000px) {
  .custom_list_group {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .modal_title {
    font-size: 35px !important;
  }

  .sub_header {
    font-size: 20px !important;
  }

  .nftImage_bg_div {
    width: 100%;
  }

  .attribute_name {
    font-size: 20px !important;
  }

  .attribute_text {
    font-size: 20px !important;
  }

  .button_div {
    width: 100% !important;
    margin-top: 0px !important  ;
  }

  .button_text {
    font-size: 25px !important;
    line-height: 40px !important;
  }
}
