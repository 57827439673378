.background {
  background-image: url("images/common/bg/mint_section_bg.png");
  background-color: #509cc6;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_inner_section {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.custom_container {
  width: 90%;
  margin: auto;
  background-color: rgba(39, 68, 87, 0.5);
}

.img_container {
  width: 80%;
  aspect-ratio: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 100px;
  border-radius: 20px;
  padding: 1px;
  background-color: transparent;
}

.img_container2 {
  width: 100% !important;
  aspect-ratio: 1 !important;
  margin: auto !important;
  border-radius: 20px !important;
  padding: 1px !important;
  background-color: transparent !important;
}

.reward_gif {
  width: 100% !important;
  background-color: white;
  border-radius: 30px;
  aspect-ratio: 1 !important;
}

.img_container div {
  position: unset;
}

.image {
  width: 100%;
  border-radius: 30px;
}

.text_1 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
  text-align: center;
}

.text_2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  color: #eeeeee;
  margin-top: 60px;
  margin-bottom: 50px;
}

.mint_button {
  background-color: white;
}

.mint_amount_div {
  width: 150px;
  height: 35px;
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #fafafa;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.mint_amount_minus_div {
  width: 25%;
  height: 100%;
  display: flex;
  cursor: pointer;
}

.mint_amount_text_div {
  width: 50%;
  height: 100%;
  display: flex;
}

.mint_amount_text {
  font-family: "Poppins", sans-serif;
  color: #1d3556;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
}

.mint_amount_plus_div {
  width: 25%;
  height: 100%;
  display: flex;
  cursor: pointer;
}

.max_amount_div {
  margin: auto;
  margin-top: 1%;
  padding: 1% 2%;
  font-family: "Poppins", sans-serif;
  border-radius: 30px;
  background-color: #fafafa;
  cursor: pointer;
}

.mint_button_text {
  font-family: "Poor Story", cursive;
  font-size: 35px;
  font-weight: 600;
  color: #e63946b2;
}

.mint_state_info_text {
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-bottom: 0;
  font-size: 30px;
}

.minted_supply_text {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  line-height: 72px;
  color: #fefefefe;
}

.line {
  background-color: #ffffff;
  width: 100%;
  height: 20px;
  border-radius: 50px;
  align-self: center;
  margin-top: 1rem;
}

.about_header_text {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 96px;
  font-size: 48px;
  color: rgba(250, 250, 250, 1);
  margin-top: 20px;
  margin-left: 40px;
  margin-bottom: 0px !important;
}

.content_text_1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: rgba(238, 238, 238, 1);
  margin-left: 40px;
  margin-bottom: 0px !important;
}

.you_have_text {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
}

.road_map_text {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  line-height: 70px;
  text-decoration: underline;
  color: #fafafa;
  cursor: pointer;
  margin-left: 40px;
}

.faq_text {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  line-height: 70px;
  text-decoration: underline;
  color: #fafafa;
  cursor: pointer;
  margin-left: 40px;
}

.road_map_div {
  text-align: left;
  padding-bottom: 10px;
}

.faq_div {
  text-align: left;
}

.mintingText {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  color: white;
}

.ticketSoldContainer {
  display: flex;
  justify-content: flex-end;
}

.ticketSoldText {
  margin: auto 0px;
  font-family: "PoppinsBold", sans-serif;
  font-size: 23px;
  color: white;
}

.leftTopInfoText {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
  text-align: center;
}

.rewardSideDiv {
  border-radius: 30px;
  overflow: hidden;
  background: rgba(53, 113, 146, 1);
}

.rewardHeader {
  font-family: "PoppinsBold", sans-serif;
  font-size: 25px;
  color: white;
  margin-right: auto;
  margin-left: auto;
}

.subHeader {
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  color: white;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px !important;
}

.subHeaderReward {
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  color: white;
  margin-right: auto;
  margin-left: auto;
}

.infoText {
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  color: white;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .custom_container {
    width: 100%;
  }

  .bg_inner_section {
    padding-bottom: 20px;
  }

  .mint_button_text {
    font-size: 30px;
  }
  .about_header_text {
    margin-left: 0px;
    text-align: center;
  }

  .minted_supply_text {
    font-size: 20px;
    background-color: red !important;
    text-align: center;
    line-height: 25px;
  }

  .reward_gif {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: auto;
    margin-left: auto;
  }

  .line {
    height: 6px;
  }

  .ticketSoldContainer {
    margin-bottom: 10px;
  }

  .about_header_text {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px !important;
    margin-top: 20px;
    line-height: 40px;
  }

  .content_text_1 {
    font-size: 15px;
    text-align: center;
    line-height: 25px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .faq_text {
    font-size: 20px;
    line-height: 0px;
    margin-left: 0px;
  }

  .road_map_text {
    font-size: 20px;
    margin-left: 0px;
    line-height: 20px !important;
  }

  .mint_amount_div {
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fafafa;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .road_map_div {
    text-align: center;
    margin-top: 20px;
  }

  .faq_div {
    text-align: center;
    margin-top: 20px;
  }

  .text_1 {
    font-size: 20px;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .text_2 {
    font-size: 20px;
    line-height: 25px;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .mint_state_info_text {
    font-size: 20px;
    margin-top: 20px;
  }
}

/*
      <div className="container mt-2">
        <Row>
          <Col lg={6} className="container">
            <div className="text-center">
              <p className={styles.text_1}>
                Mint a ticket {"&"} use it to gain access to the customization
                phase! If you are lucky enough, you can mint a golden ticket
                then use it to get a legendary snowman!
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            className="container d-flex justify-content-center flex-column m-auto"
          >
            {states.data?.mintState === false &&
            states.data?.earlyMintState === true ? (
              <p
                className="text-center"
                style={{
                  fontFamily: "Poor Story , cursive",
                  fontSize: 30,
                  marginBottom: 0,
                  color: "white",
                }}
              >
                Early mint has started.
              </p>
            ) : states.data?.mintState === true &&
              states.data?.earlyMintState === true ? (
              <p
                className="text-center"
                style={{
                  fontFamily: "Poor Story , cursive",
                  fontSize: 30,
                  marginBottom: 0,
                  color: "white",
                }}
              >
                Mint has started.
              </p>
            ) : (
              <p className={styles.mint_state_info_text}>
                Mint has not started yet
              </p>
            )}

            <Button
              variant="light"
              className={`shadow-none ${styles.mint_button}`}
              style={{
                margin: "auto",
                borderRadius: 50,
                paddingRight: 50,
                paddingLeft: 50,
              }}
              disabled={
                account.data === undefined
                  ? true
                  : states.data?.mintState === undefined &&
                    states.data?.earlyMintState === undefined
                  ? true
                  : states.data?.mintState === false &&
                    states.data?.earlyMintState === false
                  ? true
                  : false
              }
              onClick={onClick}
            >
              <span className={styles.mint_button_text}>BUY TICKET</span>
            </Button>
            <div className={styles.mint_amount_div}>
              <div
                className={styles.mint_amount_minus_div}
                onClick={() => {
                  if (mintAmount !== 1) setMintAmount(mintAmount - 1);
                }}
              >
                <AiOutlineMinus
                  className="m-auto"
                  size={25}
                  color={"#1d3557"}
                />
              </div>
              <div className={styles.mint_amount_text_div}>
                <p className={styles.mint_amount_text}>{mintAmount}</p>
              </div>
              <div
                className={styles.mint_amount_plus_div}
                onClick={() => {
                  if (mintAmount !== 20) setMintAmount(mintAmount + 1);
                }}
              >
                <AiOutlinePlus className="m-auto" size={25} color={"#1d3557"} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="container d-flex mt-2">
              <div className="m-auto">
                <span className={`${styles.minted_supply_text}`}>
                  <div
                    style={{
                      overflow: "hidden",
                      marginBottom: 20,
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {" "}
                      {supply.data === undefined ? "0" : supply.data} / 5000
                      TICKET SOLD
                    </span>
                    <div className={styles.line} />
                  </div>
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="container d-flex mt-2">
              <div className="m-auto">
                <span className={`${styles.minted_supply_text}`}>
                  <div style={{ overflow: "hidden", marginBottom: 20 }}>
                    <span>
                      {" "}
                      {price.data === undefined ? "1" : price.data} AVAX
                    </span>
                    <div className={styles.line} />
                  </div>
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="container">
            <div className="text-center">
              <p className={styles.text_2}>
                Mint a ticket and customize your snowman before it’s too late!
                There are 10 gold tickets that you can use to mint a legendary
                snowman.
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            className="container d-flex justify-content-center flex-column m-auto"
          >
            <Button
              variant="light"
              className={`shadow-none ${styles.mint_button}`}
              disabled={tickets.data?.length > 0 ? false : true}
              style={{
                margin: "auto",
                borderRadius: 50,
                paddingRight: 50,
                paddingLeft: 50,
              }}
            >
              <Link to="/customization" style={{ textDecoration: "none" }}>
                <span className={styles.mint_button_text}>USE TICKET</span>
              </Link>
            </Button>
            <p className={styles.you_have_text}>
              You have {tickets.data === undefined ? "0" : tickets.data?.length}{" "}
              {"  "}
              tickets
            </p>
          </Col>
        </Row>
      </div>*/
