.switch_container {
  position: relative;
  top: 15vh;
}

.switch_text {
  color: #434343;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  line-height: 100px;
  text-align: center;
}

.switch_button_div {
  background-color: #1d3557;
  width: 50%;
  margin: auto;
  border-radius: 30px;
  cursor: pointer;
}

.switch_button_text {
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 50px;
  line-height: 96px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .switch_text {
    font-size: 20px;
    line-height: 30px;
  }

  .switch_button_text {
    font-size: 20px;
    line-height: 30px;
  }
}
