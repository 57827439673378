.navbar_text {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #eeeeee;
  cursor: pointer;
  text-align: center;
  margin: auto;
}

.link_container {
  text-decoration: none;
  padding-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_toggle::after {
  display: none !important;
}

.dropdown_item:hover {
  background-color: inherit !important;
  color: white !important;
}

.dropdown_item {
  color: white;
}

.button_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: rgba(230, 57, 70, 0.7);
  text-align: center;
  padding: 40px;
  border-radius: 27px;
}

@media screen and (max-width: 977.5px) {
  .navbar_text {
    padding-right: 0;
    padding-bottom: 5px;
  }

  .button_text {
    font-size: 24px;
    line-height: 30px;
    color: rgba(230, 57, 70, 0.7);
    text-align: center;
    padding: 0;
    margin: auto;
    border-radius: 27px;
  }
}

/*
{account.data == undefined ? (
                <Button
                  variant="light"
                  className="shadow-none m-auto"
                  style={{ borderRadius: "27px" }}
                  onClick={connect}
                >
                  <span className={styles.button_text}>Connect</span>
                </Button>
              ) : network.hasInitialResponse && !network.isSupported ? (
                <Button
                  variant="light"
                  className="shadow-none m-auto"
                  style={{ borderRadius: "27px" }}
                  onClick={async () => {
                    if (provider) {
                      try {
                        await provider.request({
                          method: "wallet_switchEthereumChain",
                          params: [{ chainId: "0xa86a" }],
                        });
                        network.mutate();
                      } catch (error) {
                        window.location.reload();
                      }
                    }
                  }}
                >
                  <span className={styles.button_text}>
                    Switch to AVAX C-Chain
                  </span>
                </Button>
              ) : (
                <React.Fragment>
                  {isLoading ? (
                    <Button
                      variant="light"
                      className="shadow-none"
                      style={{ borderRadius: "27px" }}
                    >
                      <span className={styles.button_text}>Loading...</span>
                    </Button>
                  ) : account.data ? (
                    <Dropdown align="end" className="m-auto">
                      <Dropdown.Toggle
                        className={`shadow-none border-0 ${styles.dropdown_toggle}`}
                        style={{ backgroundColor: "transparent" }}
                      >
                        <BsWallet2
                          size={25}
                          style={{ marginBottom: "auto", marginTop: "auto" }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ backgroundColor: "#1d3557" }}>
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          style={{ cursor: "default", color: "white" }}
                        >
                          {account.data?.substring(0, 10)}
                          {"..."}
                        </Dropdown.Item>
                        <Dropdown.Item className={styles.dropdown_item}>
                          <Link to="/wallet" style={{ textDecoration: "none" }}>
                            <span
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              My Wallet
                            </span>
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : requireInstall ? (
                    <Button
                      variant="light"
                      className="shadow-none"
                      onClick={() =>
                        window.open(
                          "https://metamask.io/download.html",
                          "_blank"
                        )
                      }
                      style={{ borderRadius: "27px" }}
                    >
                      <span className={styles.button_text}>
                        Install Metamask
                      </span>
                    </Button>
                  ) : (
                    <Button
                      variant="light"
                      className="shadow-none m-auto"
                      style={{ borderRadius: "27px" }}
                      onClick={connect}
                    >
                      <span className={styles.button_text}>Connect</span>
                    </Button>
                  )}
                </React.Fragment>
              )}
*/
