.custom_modal {
  border-radius: 40px !important;
  background-color: #c7c7c7 !important;
  overflow: hidden !important;
  border-width: 0 !important;
}

.modal_title {
  color: #000000 !important;
  padding-top: 5px !important;
  margin-left: 5% !important;
  font-family: "Poppins", sans-serif;
  font-size: 30px !important;
  margin-bottom: 0 !important;
  border: 0px !important;
  margin-top: 1% !important;
}

.image {
  border-radius: 20px;
  margin: auto auto auto 5%;
}

.CustomInput {
  border: 2px solid black;
  width: 60% !important;
  margin-top: 2% !important;
  border-radius: 10px;
  height: 50px !important;
}

@media screen and (max-width: 768px) {
  .modal_title {
    text-align: center;
    margin-left: 0px;
  }
}

.custom_list_item {
  display: flex !important;
  flex-direction: row !important;
  background-color: transparent !important;
  border-width: 0px !important;
  width: 100% !important;
  padding: 5px !important;
}

.list_circle {
  height: 12px;
  width: 12px;
  background-color: black;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  border-width: 0px;
}

.rarity_text {
  margin-right: auto;
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
  font-size: 25px;
}

.rarity_circle {
  height: 20px;
  width: 20px;
  background-color: #e63946;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  border-width: 0px;
  margin-left: auto;
}

.percentage_text {
  text-align: center;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.attribute_name {
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 23px;
}

.attribute_real_name {
  width: 50%;
  margin-left: auto;
  margin-right: 10px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  border-radius: 50px !important;
  flex-direction: column;
}

.attribute_text {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-size: 23px;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 25px;
}

.announced_soon_text {
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 1.3rem;
  font-size: 1.2rem;
}

@media screen and (max-width: 1000px) {
  .modal_title {
    padding-top: 5%;
  }

  .image {
    margin: auto !important;
  }

  .custom_col {
    margin-top: 5% !important;
  }

  .custom_list_item {
    display: flex !important;
    border-width: 0px !important;
    width: 100%;
    padding: 2px !important;
  }

  .attribute_name {
    font-size: 20px !important;
  }

  .attribute_text {
    font-size: 20px !important;
  }
}
