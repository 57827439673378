.card_container {
  width: 220px;
  height: 300px;
  border-radius: 20px;
  background-color: #fefefe;
  margin: auto;
  margin-bottom: 10px;
  transition: 0.1;
  margin-top: box-shadow 0.3s;
}

.name {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 50px;
  color: black;
  margin: auto;
}

.role {
  margin: auto;
  color: rgb(230, 57, 70);
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.card_container:hover {
  box-shadow: 0 0 11px rgba(207, 92, 92, 0.2);
}
