.custom_card {
  box-shadow: 5px 10px #1d3557;
  cursor: pointer;
  height: 300px;
  width: 300px;
  overflow: hidden;
  margin: auto;
  display: flex;
}

.card_body_text {
  font-family: "Poppins", sans-serif;
  color: #1d3557;
  margin: auto;
  font-size: 1rem;
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 4px;
}

.custom_image {
  height: auto !important;
  width: 100% !important;
  max-height: 350px !important;
}

.custom_modal {
  border-radius: 40px;
  background-color: #c7c7c7 !important;
}

.modal_title {
  color: #000000;
  padding-top: 15px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  margin-bottom: 0;
  border: 0px;
  border-width: 0px;
}

.rarity_header {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  margin-bottom: 0;
  color: purple;
}

.nftImage_bg_div {
  width: 300px;
  height: 300px;
  background-color: #fefefe;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 30px;
  display: flex;
}

.nft_image {
  padding: 10px;
  border-radius: 30px;
}

.announced_text {
  color: #1d3557;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

.custom_list_item {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-width: 0px;
  width: 100%;
  padding: 2px;
}

.list_circle {
  height: 12px;
  width: 12px;
  background-color: #e63946;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  border-width: 0px;
}

.attribute_name {
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
  color: #fafafa;
}

.attribute_real_name {
  width: 60% !important;
  margin-left: auto;
  margin-right: 10px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  border-radius: 15px;
}

.attribute_text {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-size: 20px;
  width: 100%;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 25px;
}

.rarity_text {
  text-align: center;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: white;
  margin: auto;
  margin-top: 2rem;
}

.see_on_text {
  position: absolute;
  bottom: 2px;
  text-decoration: underline;
  color: lightblue;
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  cursor: pointer;
}

.image_container {
  width: 40vw;
  height: 40vh;
}

.image_container .image {
  width: 50%;
  height: 50%;
  object-fit: cover;
  position: relative !important;
}
