.custom_modal {
  border-radius: 40px;
  background-color: #c7c7c7 !important;
}

.modal_title_div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modal_title {
  color: #000000;
  padding-top: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  margin-bottom: 0;
}

.modal_title_ticket_type_div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ticket_regular_div {
  width: 200px;
  height: 40px;
  border-radius: 30px;
}

.ticket_regular_active_div {
  background: #1d3557;
  color: #fefefe;
}

.ticket_golden_active_div {
  background-color: goldenrod;
  columns: #fefefe;
}

.ticket_regular_passive_div {
  border: 1px solid #1d3557;
}

.ticket_golden_passive_div {
  border: 1px solid goldenrod;
}

.ticket_golden_div {
  width: 200px;
  border-radius: 30px;
}

.ticket_regular_text {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-bottom: 0px;
}

.nft_header {
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  text-align: center;
  margin-top: 5%;
  color: #fafafa;
  margin-bottom: 0;
}

.attributes_div {
  background: #1d3557;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  margin: auto;
}

.attributes_header {
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  line-height: 96px;
  margin-bottom: 0px;
  margin-left: 5%;
}

.attributes_circle {
  height: 28px;
  width: 28px;
  background-color: #e63946;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  border-width: 0px;
}

.attributes_text {
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.2rem;
}

.attributes_custom_dropdown {
  border: 3px solid #1d3557 !important;
  border-radius: 30px !important;
  padding: 2px !important;
  text-overflow: clip !important;
}

.attribute_name {
  font-family: "Poppins", sans-serif;
  font-size: 20px !important;
}

.custom_dropdown_item {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  color: #000000 !important;
  padding: 0 !important;
  margin-bottom: 0px !important;
  text-align: center !important;
}

.custom_dropdown_item:hover {
  background-color: #e63946 !important;
  color: white !important;
}

.custom_dropdown_divider {
  width: 100% !important;
  margin: auto !important;
  background-color: #bfbfbf;
}

.left_arrow_div {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  padding-right: 2%;
}

.nft_div {
  height: 410px;
  width: 410px;
  border-radius: 30px;
  background-color: #fefefe;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
}

.nft_div_layer {
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.right_arrow_div {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  padding-left: 2%;
}

.custom_body {
  min-width: 100%;
  min-height: 100%;
}

.create_button {
  background-color: #1d3557;
  border-radius: 25px;
  border-width: 0;
  color: white;
  cursor: pointer;
}

.create_button:hover {
  background: white;
  color: #1d3557;
}

.create_button_text {
  font-family: "Poor Story", cursive;
  font-size: 30px;
}

.custom_row {
  cursor: pointer;
  background: #cfe3ff;
  display: flex;
  width: 95% !important;
  border-radius: 23px;
  margin: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 2% !important;
}

.infoText {
  font-weight: 100;
  font-size: 18px;
  color: red;
  margin: auto 0;
}

@media screen and (max-width: 1000px) {
  .attributes_div {
    margin-top: 5%;
  }

  .custom_biggest_row {
    padding-bottom: 15%;
  }
}

@media screen and (max-width: 768px) {
  .attributes_div {
    margin-top: 5%;
  }

  .modal_title {
    font-size: 30px;
  }

  .custom_row {
    width: 100% !important;
    margin-bottom: 5% !important;
  }

  .modal_title_ticket_type_div {
    display: flex;
    flex-direction: column;
    padding-block: 10px;
    margin: auto;
  }

  .ticket_regular_div {
    margin-bottom: 20px;
  }

  .attributes_header {
    margin-left: 0;
    text-align: center;
  }

  .attributes_text {
    margin-left: 35%;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
  }

  .attributes_custom_dropdown {
    margin: auto !important;
  }

  .nft_header {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .nft_div {
    height: auto;
    aspect-ratio: 1 / 1;
    width: 90%;
    padding: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }
}
