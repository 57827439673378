.accordionn {
  width: 90%;
  margin: 1rem auto;
}

.accordion__button {
  padding: 1rem;
  border: none;
  width: 100%;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  color: red;
  background-color: white;
}

.accordion__drawer {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.accordion__paragraph {
  margin: 0;
  padding: 1rem;
  color: white;
  font-family: "Poppins", sans-serif;
}
