.custom_card {
  box-shadow: 5px 10px #1d3557;
  min-width: 250px !important;
}

.card_title_div {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.cardImage {
}

.card_title {
  text-align: center;
}

.card_title_token {
  text-align: center;
  color: #1d3557;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.card_title_type {
  text-align: center;
  color: #1d3557;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}
