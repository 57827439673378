.header {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  color: #1d3557;
  margin: auto;
  text-align: center;
}

.ticket_header {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  color: #1d3557;
  text-decoration: underline;
  margin-top: auto;
  margin-bottom: auto;
}

.claim_reward_button_text {
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
}

.reflection_balance_text {
  font-family: "Poppins", sans-serif;
}

.use_ticket_button {
  height: 50%;
  margin-right: auto !important;
  margin-left: 50px;
  border-width: 0px;
  background-color: #1d3557 !important;
}

.use_ticket_button_text {
  color: white;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

.use_ticket_button:hover {
  background-color: #1d3557;
  border-color: #1d3557;
}

.use_ticket_button:hover .use_ticket_button_text {
  color: white;
}

.rarity_div {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  display: flex;
  cursor: pointer;
}

.rarity_text {
  margin-right: 10px;
  color: #1d3557;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-bottom: 0;
}

.custom_popover_body {
  background-color: #efefef;
  position: relative;
  bottom: 5px;
  right: 5px;
  border-radius: 20px;
  margin: auto;
  width: 12.5rem !important;
}

.custom_popover {
  background-color: transparent !important;
}

.popover_rarity_text {
  margin-left: 12px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  float: left;
  margin-bottom: 0px !important;
}

.rarity_circles {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: gray;
  margin-top: auto;
  margin-bottom: auto;
}

.custom_row {
  width: 85%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .header {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    color: #1d3557;
    margin: auto;
    text-align: center;
  }

  .ticket_header {
    text-align: center;
  }

  .use_ticket_button {
    margin-top: 5%;
  }

  .generate_random_button {
    margin-top: 20px;
  }

  .rarity_div {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .use_ticket_button_text {
    font-size: 15px !important;
  }
}
