.daoHeader {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: center;
}

.customRow {
  padding-bottom: 10px !important;
  width: 90%;
  background: #357192;
  padding: 1%;
  padding-left: 2%;
  border-radius: 30px;
  margin: auto !important;
  margin-bottom: 10px !important;
}

.headerContent {
  font-family: "Poppins", sans-serif;
  color: rgba(238, 238, 238, 1);
  font-size: 25px;
  padding: 2% 0px;
}

.itemHeader {
  font-family: "Poppins", sans-serif;
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 15px;
}

.itemContent {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 23px;
  line-height: 35px;
}

@media screen and (max-width: 1000px) {
  .itemHeader {
    text-align: center;
    width: 100%;
  }

  .daoHeader {
    font-size: 35px;
    margin-top: 5%;
  }

  .itemContent {
    width: 90%;
    margin: auto;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
  }

  .headerContent {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
  }
}
