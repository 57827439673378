.custom_row {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
}

.dao_name {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 25px;
  padding: 20px 0px;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
}

@media screen AND (max-width: 990px) {
  .custom_row {
    width: 80%;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .dao_name {
    margin-left: 0px;
    text-align: center;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
