.custom_modal {
  border-radius: 40px !important;
  background-color: #c7c7c7 !important;

  overflow: hidden !important;
  border-width: 0px !important;
}

.header {
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0px !important;
  font-size: 30px !important;
  margin-top: 2% !important;
}

.valueText {
  margin-bottom: 0px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
}

.createText {
  font-family: "Poppins", sans-serif;
}

.ticketDiv {
  width: 70px;
  height: 70px;
  display: flex;
  background-color: gray;
  margin: auto !important;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  outline: none;
  transition: 0.2s all;
}

.ticketDiv:active {
  transform: scale(0.5);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.ticketText {
  font-family: "Poppins", sans-serif;
  margin: auto !important;
  color: white;
  font-size: 25px;
}

.infoText {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.timerText {
  font-family: "Poppins", sans-serif;
  font-size: 35px !important;
  color: red;
}

@media screen and (max-width: 1000px) {
  .header {
    margin-bottom: 0px !important;
    font-size: 25px !important;
    margin-top: 5% !important;
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
}
