.team_container {
  background-image: url("../../../../images/common/bg/team_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 90px;
  color: rgba(250, 250, 250, 1);
  padding-left: 50px;
  margin-bottom: 0px;
}

.content {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  width: 50%;
  color: rgba(250, 250, 250, 1);
  padding-left: 50px;
}

.custom_col {
  margin-right: auto !important;
  margin-left: auto !important;
  display: flex;
  justify-content: center;
}

.custom_row {
  width: 90% !important;
  margin: auto !important;
}

.poweredContainer {
  display: flex;
  justify-content: flex-end;
}

.poweredImage {
  width: 20%;
  cursor: pointer;
}

@media screen and (max-width: 977.5px) {
  .header {
    font-size: 30px;
    text-align: center;
    padding-left: 0px;
    line-height: 40px;
    padding-top: 20px;
  }

  .content {
    font-size: 20px;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }

  .custom_row {
    width: 100% !important;
  }

  .poweredImage {
    width: 35%;
  }

  .poweredContainer {
    justify-content: center;
  }
}
