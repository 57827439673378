.header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 90px;
  color: rgba(250, 250, 250, 1);
  padding-left: 50px;
  margin-bottom: 0px;
}

.content {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  width: 50%;
  color: rgba(250, 250, 250, 1);
  padding-left: 50px;
}

.item {
  width: 90%;
  margin: auto;
  background-color: red;
  margin-top: 8px;
  border-radius: 70px;
  flex-direction: row;
  overflow: hidden;
}

.circle {
  width: 150px;
  max-width: 200px;
  max-height: 150px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: white;
  display: flex;
  margin: 5%;
}

.circle_75 {
  width: 150px;
  max-width: 200px;
  max-height: 150px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: white;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5%;
}

.percantage_text {
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  margin: auto;
}

.list {
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  overflow: auto;
  width: 90%;
}

.list_item {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.list_item_s {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.year {
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: white;
  font-size: 5rem;
  margin-bottom: 0%;
}

@media screen and (max-width: 977.5px) {
  .content {
    width: 100%;
  }
  .circle {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin: auto;
    height: 150px !important;
    aspect-ratio: 1 !important;
  }

  .circle_75 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin: auto;
    height: 150px !important;
    aspect-ratio: 1 !important;
  }

  .list {
    width: 90%;
    margin: auto;
  }

  .header {
    font-size: 35px;
    text-align: center;
    padding-left: 0px;
    line-height: 40px;
    padding-top: 20px;
    margin-bottom: 15px;
  }

  /* .content {
    font-size: 5vw;
    line-height: 25px;
    text-align: center;
    padding-left: 0px !important;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 10px !important;
  } */

  .content {
    font-size: 20px;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}
